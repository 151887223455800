import React, {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import SingleBooleanField from './singleBooleanField';
import Session from './session';
import RoundDuration from './roundDuration';
import CustomResourcePool from './customResourcePool';
import SingleStringField from './singleStringField';
import CertificationName from './certificationName';
import WinLimits from './winLimit';
import FreeBets from './freebets/freebets';
import Rtp from './rtp';
import DisplayRtp from './displayRtp';
import AvailableBets from './availableBets/availableBets';
import Jackpot from './jackpot';
import MessageEvents from './messageEvents/messageEvents';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';
import {useSelector} from "react-redux";
import {userSelectors} from "../../../redux/user/selectors";
import eUserRoles from "../../../enums/eUserRoles";
import eOperatorsConfigsFieldName from '../../operatorsConfigs/eOperatorsConfigsFieldName';

const singleBooleanFields = {
  [ePartnerConfigFieldName.EPCFN_IFRAME_HOME_BUTTON]: 'Frame home button',
  [ePartnerConfigFieldName.EPCFN_HOME_BUTTON_HISTORY_BACK_DISABLE]: 'Home Button History Back Disable',
  [ePartnerConfigFieldName.EPCFN_CANCEL_ENABLED]: 'Cancel enabled',
  [ePartnerConfigFieldName.EPCFN_IS_MAX_BETS_COUNT_ONE]: 'Is max bets count one',
  [ePartnerConfigFieldName.EPCFN_SHOW_OPERATOR_BETS_IDS]: 'Show operator bets ids',
  [ePartnerConfigFieldName.EPCFN_DISABLE_BUY_BONUS]: 'Disable buy bonus',
  [ePartnerConfigFieldName.EPCFN_SHOW_RULES_ON_START]: 'Show rules on start',
  [ePartnerConfigFieldName.EPCFN_HIDE_RULES_DOWNLOAD_BUTTON]: 'Hide rules download button',
  [ePartnerConfigFieldName.EPCFN_SHOW_GAME_STATE]: 'Show game state',
  [ePartnerConfigFieldName.EPCFN_DISABLE_AUTOPLAY]: 'Disable autoplay',
  [ePartnerConfigFieldName.EPCFN_DISABLE_SPIN_BY_SPACE]: 'Disable spin by space',
  [ePartnerConfigFieldName.EPCFN_SHOW_STOPWATCH]: 'Show stopwatch',
  [ePartnerConfigFieldName.EPCFN_SHOW_ClOCK]: 'Show clock',
  [ePartnerConfigFieldName.EPCFN_SHOW_NET_POSITION]: 'Show net position',
  [ePartnerConfigFieldName.EPCFN_ENABLE_REALITY_CHECK]: 'Enable reality check',
  [ePartnerConfigFieldName.EPCFN_SKIP_BLOCKED]: 'Skip blocked',
  [ePartnerConfigFieldName.EPCFN_LIGHT_MODE]: 'Light mode',
  [ePartnerConfigFieldName.EPCFN_SLOT_MODE]: 'Slot mode (for tappers)',
  [eOperatorsConfigsFieldName.EPCFN_HIDE_NON_PLAYER_CURRENCIES]: 'Hide non player currencies',
  [ePartnerConfigFieldName.EPCFN_BET_INDEX_CUSTOM_RANGE_SETUP]: 'Bet index custom range setup',
  [ePartnerConfigFieldName.EPCFN_ENABLE_SOCIAL_LANGUAGE]: 'Enable social language',
  [eOperatorsConfigsFieldName.EPCFN_USE_LOW_QUALITY_ASSETS]: 'Use Low Quality Assets',
  [eOperatorsConfigsFieldName.EPCFN_IS_ROUNDS_HISTORY_AVAILABLE]: 'Is rounds history available',
};

const singleStringFields = {
  [eOperatorsConfigsFieldName.EPCFN_TOURNAMENT_PAYOUT_CALLBACK_URL]: 'Tournament payout callback url',
};

export default React.memo(forwardRef(function ConfigEditor({
                                                             config,
                                                             casinoConfig,
                                                             setSaveFunctionPool,
                                                             selectedCasino,
                                                             overriddenBlocks,
                                                             setOverriddenBlocks,
                                                             getConfigOrCasinoConfigProperty,
                                                             getConfigOrCasinoConfig
                                                           }, ref) {
  const user = useSelector(userSelectors.getUser);
  const canEditFields = useMemo(() => {
    if ([eUserRoles.EUR_ADMIN, eUserRoles.EUR_INTEGRATION_MANAGER].includes(user.role)) {
      return new Set(Object.values(ePartnerConfigFieldName));
    } else {
      return new Set(user.partnerConfigAllowToEditFields || []);
    }
  }, [user]);

  const fields = useRef(Object.fromEntries(Object.values(ePartnerConfigFieldName).map(fieldName => [fieldName, React.createRef()])));

  const reset = () => {
    Object.values(fields.current).forEach(ref => {
      if (!ref.current) return;
      ref.current.reset()
    })
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body'}>

      {Object.keys(singleBooleanFields).map(key =>
        <SingleBooleanField ref={fields.current[key]} key={key} fieldName={key} title={singleBooleanFields[key]}
                            initialValue={false}
                            canChangeBlock={(!selectedCasino || overriddenBlocks[key]) && canEditFields.has(key)}
                            canOverride={canEditFields.has(key)}
                            config={config} setSaveFunctionPool={setSaveFunctionPool}
                            selectedCasino={selectedCasino} overriddenBlocks={overriddenBlocks}
                            setOverriddenBlocks={setOverriddenBlocks}
                            getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty} info={''}/>
      )}
      {Object.keys(singleStringFields).map(key =>
        <SingleStringField ref={fields.current[key]} key={key} fieldName={key} title={singleStringFields[key]} config={config}
                          setSaveFunctionPool={setSaveFunctionPool}
                          canChangeBlock={(!selectedCasino || !!overriddenBlocks[key]) && canEditFields.has(key)}
                          canOverride={canEditFields.has(key)}
                          getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                          selectedCasino={selectedCasino}
                          overriddenBlocks={overriddenBlocks}
                          setOverriddenBlocks={setOverriddenBlocks}
        />
      )}

      <CertificationName ref={fields.current[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME]} config={config}
                         setSaveFunctionPool={setSaveFunctionPool}
                         canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME)}
                         canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME)}
                         getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                         selectedCasino={selectedCasino}
                         overriddenBlocks={overriddenBlocks}
                         setOverriddenBlocks={setOverriddenBlocks}
      />

      <RoundDuration ref={fields.current[ePartnerConfigFieldName.EPCFN_ROUND_DURATION]} config={config}
                     setSaveFunctionPool={setSaveFunctionPool}
                     canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_ROUND_DURATION]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_ROUND_DURATION)}
                     canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_ROUND_DURATION)}
                     getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                     selectedCasino={selectedCasino}
                     overriddenBlocks={overriddenBlocks}
                     setOverriddenBlocks={setOverriddenBlocks}
      />
      <CustomResourcePool ref={fields.current[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL]} config={config}
               setSaveFunctionPool={setSaveFunctionPool}
               canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL)}
               canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL)}
               getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
               selectedCasino={selectedCasino}
               overriddenBlocks={overriddenBlocks}
               setOverriddenBlocks={setOverriddenBlocks}
      />
      <Session ref={fields.current[ePartnerConfigFieldName.EPCFN_SESSION]} config={config}
               setSaveFunctionPool={setSaveFunctionPool}
               canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_SESSION]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_SESSION)}
               canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_SESSION)}
               getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
               selectedCasino={selectedCasino}
               overriddenBlocks={overriddenBlocks}
               setOverriddenBlocks={setOverriddenBlocks}
      />

      <WinLimits ref={fields.current[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT]} config={config}
                 canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT] || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]) && (canEditFields.has(ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT) || canEditFields.has(ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START))}
                 canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT) || canEditFields.has(ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START)}
                 setSaveFunctionPool={setSaveFunctionPool}
                 getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                 selectedCasino={selectedCasino}
                 overriddenBlocks={overriddenBlocks}
                 setOverriddenBlocks={setOverriddenBlocks}
                 getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <FreeBets ref={fields.current[ePartnerConfigFieldName.EPCFN_FREE_BETS]} config={config}
                canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_FREE_BETS]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_FREE_BETS)}
                canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_FREE_BETS)}
                setSaveFunctionPool={setSaveFunctionPool}
                getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                selectedCasino={selectedCasino}
                overriddenBlocks={overriddenBlocks}
                setOverriddenBlocks={setOverriddenBlocks}
                getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <Rtp ref={fields.current[ePartnerConfigFieldName.EPCFN_CUSTOM_RTP]} config={config}
           canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_CUSTOM_RTP]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_CUSTOM_RTP)}
           canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_CUSTOM_RTP)}
           setSaveFunctionPool={setSaveFunctionPool}
           getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
           selectedCasino={selectedCasino}
           overriddenBlocks={overriddenBlocks}
           setOverriddenBlocks={setOverriddenBlocks}
           casinoConfig={casinoConfig}
           getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <DisplayRtp ref={fields.current[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]} config={config}
                  canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_DISPLAY_RTP)}
                  canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_DISPLAY_RTP)}
                  setSaveFunctionPool={setSaveFunctionPool}
                  getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                  selectedCasino={selectedCasino}
                  overriddenBlocks={overriddenBlocks}
                  setOverriddenBlocks={setOverriddenBlocks}
                  casinoConfig={casinoConfig}
                  getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <Jackpot ref={fields.current[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]} config={config}
               canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED)}
               canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED)}
               setSaveFunctionPool={setSaveFunctionPool}
               selectedCasino={selectedCasino}
               overriddenBlocks={overriddenBlocks}
               setOverriddenBlocks={setOverriddenBlocks}
               getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <AvailableBets ref={fields.current[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]} config={config}
                     canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS)}
                     canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS)}
                     setSaveFunctionPool={setSaveFunctionPool}
                     selectedCasino={selectedCasino}
                     overriddenBlocks={overriddenBlocks}
                     setOverriddenBlocks={setOverriddenBlocks}
                     getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />

      <MessageEvents ref={fields.current[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]} config={config}
                     canChangeBlock={(!selectedCasino || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]) && canEditFields.has(ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS)}
                     canOverride={canEditFields.has(ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS)}
                     setSaveFunctionPool={setSaveFunctionPool}
                     getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                     selectedCasino={selectedCasino}
                     overriddenBlocks={overriddenBlocks}
                     setOverriddenBlocks={setOverriddenBlocks}
                     casinoConfig={casinoConfig}
                     getConfigOrCasinoConfig={getConfigOrCasinoConfig}
      />
    </div>
  );
}));
