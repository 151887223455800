import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';

const defaultValue = '';

export default React.memo(forwardRef(function SingleStringField({
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              overriddenBlocks,
                                                              toggleOverriddenField,
                                                              fieldName,
                                                              title,
                                                              configForView,
                                                              setValueToConfigForView
                                                            }, ref) {
  const [textData, setTextData] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[fieldName] === configForView.type) {
        data[fieldName] = textData;
    }
    return data;
  }, [overriddenBlocks, textData, configForView.isGlobalConfig, configForView.type, fieldName]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [fieldName]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, fieldName, configForView.type]);

  useEffect(() => {
    const value = configForView.config[fieldName] !== undefined ? configForView.config[fieldName] : defaultValue;
    setTextData(value);
  }, [configForView.config, setTextData, fieldName]);

  const changeInterval = (e) => {
    setTextData(e.target.value);
  };

  const onBlurText = (e) => {
    setValueToConfigForView(configForView.type, fieldName, e.target.value);
  };

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, fieldName);
  }, [toggleOverriddenField, fieldName, configForView.type]);

  const reset = useCallback(() => {
    const value = configForView.config[fieldName] !== undefined ? configForView.config[fieldName] : defaultValue;
    setTextData(value);
  }, [configForView.config, setTextData, fieldName]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'operators_configs_body_form_box_title'}>{title}</span>
        <div className={'operators_configs_override'}>
          <input type={'text'} className={'medium_input'} value={textData} onBlur={onBlurText} onChange={changeInterval}
                 disabled={!canChangeBlock}/>
        <Info info={''}/>
        {!configForView.isGlobalConfig && canOverride &&
            <AddCheckboxLayout title={'override'}
                               checked={overriddenBlocks[fieldName] === configForView.type}
                               checkboxId={`${configForView.type}_${fieldName}_override_checkbox`}
                               setChecked={checkOverride}
                               disabled={!canOverride}
                               additionalInfo={overriddenBlocks[fieldName]}
            />
        }
        </div>
      </div>
    </div>
  );
}));
